import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { AccessLevel } from './model/enum';
import { IGetUserBlob } from './model';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Landing from './pages/Landing';
import Loading from './componets/Loading/Loading';
import { LoggedUserContext } from './componets/UserContext/userContext';
import { RouterProvider } from 'react-router-dom';
import { TOKEN } from './utils';
import { getUserBlob } from './api/fetch';
import { loginRequest } from './msal/azureAd';
import { routesByAl } from './route';
import styled from 'styled-components';

interface IAppProps {}

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const App = (props: IAppProps) => {
    const { accounts, instance } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);
    const activeAccount = instance.getActiveAccount();
    const { userBlob, isLoading } = getUserBlob(activeAccount?.localAccountId);
    const [loggedUser, setLoggedUser] = useState<IGetUserBlob | undefined>(undefined);

    const checkRedirect = async () => {
        setLoading(true);
        try {
            await instance.handleRedirectPromise();
            let activeAcc = instance.getActiveAccount();
            let tokenSilent;

            if (!activeAcc && accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
                tokenSilent = await instance.acquireTokenSilent({ account: accounts[0], ...loginRequest });
            }

            if (!!activeAcc) {
                tokenSilent = await instance.acquireTokenSilent({ account: activeAcc, ...loginRequest });
            }
            if (!!tokenSilent) {
                localStorage.setItem(TOKEN, tokenSilent.accessToken);
            }
        } catch (err) {
            console.log(err);
            if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(loginRequest);
            } else {
                instance.logoutRedirect();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkRedirect();
    }, [accounts]);

    useEffect(() => {
        if (!isLoading && !!userBlob) {
            setLoggedUser(userBlob);
        }
        if (!loading && !isLoading && userBlob === null) {
            setLoggedUser({
                userObjectId: activeAccount?.localAccountId ?? '',
                serviceOfUsers: [],
                accessLevel: AccessLevel.None,
                userName: activeAccount?.name ?? '',
                userSurname: activeAccount?.username ?? '',
            });
        }
    }, [isLoading, userBlob]);

    //iframe check
    // useEffect(() => {
    //     if (!!window && !!window.top && window.self !== window.top) {
    //         window.top.location = window.location.href;
    //     }
    // }, []);

    return (
        <>
            <AuthenticatedTemplate>
                {loading || isLoading || !loggedUser ? (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                ) : (
                    <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
                        <RouterProvider router={routesByAl[loggedUser.accessLevel as unknown as AccessLevel]} />
                    </LoggedUserContext.Provider>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Landing />
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
