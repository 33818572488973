import { AccessLevel } from '../model/enum';
import CryptoJS from 'crypto-js';
import { format } from 'date-fns';

export const getNegativeRandom = () => {
    return -Math.floor(Math.random() * 1000000000000000);
};
export const TOKEN = 'token';
export const formatDate = (date?: string, removeHours?: boolean): string => {
    try {
        if (!date) return '';
        let dateTime = new Date(date);
        if (isNaN(dateTime.getTime())) {
            return '';
        }
        if (removeHours) {
            dateTime.setHours(dateTime.getHours() + 2);
        }
        return format(dateTime, 'dd/MM/yyyy HH:mm');
    } catch (err) {
        console.log(err);
        return '';
    }
};

export const isSiag = (role: string) => !!role && role === 'SIAG';

// Regex per il codice fiscale
const codiceFiscaleRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;

// Regex per la partita IVA
const partitaIvaRegex = /^\d{11}$/;

export const isValidTaxCode = (cf: string) => {
    return codiceFiscaleRegex.test(cf);
};

export const isValidPartitaIva = (piva: string) => {
    return partitaIvaRegex.test(piva);
};

export const isValidTaxId = (id: string) => {
    return isValidTaxCode(id) || isValidPartitaIva(id);
};

export const isServiceManager = (al?: AccessLevel): boolean => !!al && al === AccessLevel.ServiceManager;
export const isAdmin = (al?: AccessLevel): boolean => !!al && al === AccessLevel.Admin;

export const isWriter = (al?: AccessLevel): boolean => !!al && al === AccessLevel.Writer;

export const cryptData = (data: any, withoutJson?: boolean): string => {
    return data;
    if (!!data) {
        const dataStringfied = withoutJson ? data : JSON.stringify(data);
        const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY ?? '');
        const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV ?? '');
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(dataStringfied), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
    return '';
};
export const decryptData = (data: any): any => {
    return data;
    if (!!data) {
        const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY ?? '');
        const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV ?? '');
        const de = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        let decryptedData = de.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }
    return '';
};

export const getNowMinus2Hour = () => {
    const startDate = new Date().setHours(new Date().getHours() - 2);
    return new Date(startDate).toISOString();
};

export const getNowPlus100Year = () => {
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 100);
    return new Date(endDate).toISOString();
};
export const getNowPlus100YearForCsv = () => {
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 100);
    return new Date(endDate);
};
const isValidDateFormat = (date: string) => {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(date);
};

export const parseDate = (date: string) => {
    const [day, month, year] = date.split('/').map(Number);
    return new Date(year, month - 1, day);
};

export const validateDates = (serviceStart: string, serviceEnd: string, roleStart?: string, roleEnd?: string) => {
    if (!!roleStart && roleEnd) {
        if (![serviceStart, serviceEnd, roleStart, roleEnd].every(isValidDateFormat)) {
            return false;
        }

        const serviceStartDate = parseDate(serviceStart);
        const serviceEndDate = parseDate(serviceEnd);
        const roleStartDate = parseDate(roleStart);
        const roleEndDate = parseDate(roleEnd);

        return !(roleStartDate < serviceStartDate || roleStartDate >= roleEndDate || roleEndDate > serviceEndDate || roleEndDate <= roleStartDate);
    } else {
        if (![serviceStart, serviceEnd].every(isValidDateFormat)) {
            return false;
        }

        const serviceStartDate = parseDate(serviceStart);
        const serviceEndDate = parseDate(serviceEnd);
        return !(serviceStartDate < serviceEndDate);
    }
};
